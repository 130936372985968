export const DisconnectIcon = ({ className, color = "#2F2F2F" }: IconType) => {
  return (
    <svg
      className={className}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.5 6.85312L21.6562 12L16.5 17.1469V14.625C16.5 14.0016 15.9984 13.5 15.375 13.5H9.75V10.5H15.375C15.9984 10.5 16.5 9.99844 16.5 9.375V6.85312ZM24 12C24 11.4609 23.7844 10.9453 23.4047 10.5656L17.9625 5.1375C17.5547 4.72969 17.0016 4.5 16.425 4.5C15.225 4.5 14.25 5.475 14.25 6.675V8.25H9.75C8.50781 8.25 7.5 9.25781 7.5 10.5V13.5C7.5 14.7422 8.50781 15.75 9.75 15.75H14.25V17.325C14.25 18.525 15.225 19.5 16.425 19.5C17.0016 19.5 17.5547 19.2703 17.9625 18.8625L23.4047 13.4344C23.7844 13.0547 24 12.5391 24 12ZM7.875 3.75C8.49844 3.75 9 3.24844 9 2.625C9 2.00156 8.49844 1.5 7.875 1.5H4.125C1.84688 1.5 0 3.34688 0 5.625V18.375C0 20.6531 1.84688 22.5 4.125 22.5H7.875C8.49844 22.5 9 21.9984 9 21.375C9 20.7516 8.49844 20.25 7.875 20.25H4.125C3.08906 20.25 2.25 19.4109 2.25 18.375V5.625C2.25 4.58906 3.08906 3.75 4.125 3.75H7.875Z"
        fill={color}
      />
    </svg>
  )
}
