import { zodResolver } from "@hookform/resolvers/zod"
import { CaptchaInfo } from "components/CaptchaManager/CaptchaManager"
import { useCognitoContext } from "components/Providers/CognitoProvider"
import { checkUserNeedsPasswordChange } from "utils/requests/checkUserNeedsPasswordChange"
import { useRouter, useSearchParams } from "next/navigation"
import { useEffect, useRef, useState } from "react"
import { FieldErrorsImpl, useForm, UseFormRegister } from "react-hook-form"
import { useSignInRedirection } from "utils/hooks/useSignInRedirection"
import { updateABUserProfile } from "utils/requests/updateABUserProfile"
import * as z from "zod"
import { getUserProfile } from "utils/requests/getUserProfile"
import { useLogout } from "@account-kit/react"

export const SIGN_IN_FORM_VALIDATION = z
  .object({
    password: z.string().optional(),
    email: z.string().email().max(255),
    rememberMe: z.boolean().optional(),
  })
  .required()

export type SignInFormValues = {
  email: string
  password: string
  rememberMe?: boolean
}

export interface SignInFormSelectors {
  rememberMe: boolean
  errors: Partial<FieldErrorsImpl<SignInFormValues>>
  emailSent: boolean
  actionError: string | null
  isSigningIn: boolean
  isConnected: boolean
  isValid: boolean
  tries: number
}

export interface SignInFormActions {
  handleSignInForm: () => void
  register: UseFormRegister<SignInFormValues>
  handleEmailSentReset: () => void
  handleSignInAgain: () => void
  setCaptchaInfo: (result: CaptchaInfo | null) => void
}

export interface SignInFormHook {
  selectors: SignInFormSelectors
  actions: SignInFormActions
}

export function useSignInForm(): SignInFormHook {
  const router = useRouter()
  const searchParams = useSearchParams()
  const tries = useRef(0)
  const { logout } = useLogout()
  const [captchaInfo, setCaptchaInfo] = useState<CaptchaInfo | null>(null)
  const [isSigningIn, setIsSigningIn] = useState(false)
  const [emailSent, setEmailSent] = useState(false)
  const [actionError, setActionError] = useState<string | null>(null)
  const [isConnected, setIsConnected] = useState(false)
  const { user, session, handleSignIn, handleSignOut, handleForgotPassword } =
    useCognitoContext()
  const {
    actions: { handleRedirection },
  } = useSignInRedirection()

  const {
    watch,
    register,
    handleSubmit,
    formState: { errors, isValid, touchedFields },
    reset,
  } = useForm<SignInFormValues>({
    mode: "all",
    resolver: zodResolver(SIGN_IN_FORM_VALIDATION),
    defaultValues: {
      email: "",
      password: "",
      rememberMe: false,
    },
  })

  const password = watch("password")
  const rememberMe = watch("rememberMe") || false

  const sendForgotPasswordEmail = async (email: string) => {
    try {
      await handleForgotPassword(email)
      reset()
      router.push("/need-reset-password-email-sent")
    } catch (error: any) {
      tries.current += 1
      setCaptchaInfo(null)
      setActionError(
        error.message ||
          "Failed to send password reset email, please try again later"
      )
    }
  }

  const sendConfirmationEmail = () => {
    setEmailSent(true)
    setIsSigningIn(false)
    reset()
  }

  const sendMigratedUserRequest = async ({
    accessToken,
    idToken,
  }: {
    accessToken: string
    idToken: string
  }) => {
    try {
      await updateABUserProfile({
        accessToken,
        idToken,
      })

      setIsConnected(true)
    } catch (error: any) {
      throw new Error(
        error.message || "Failed to update user profile, please try again later"
      )
    }
  }

  const handleSignInForm = handleSubmit(
    async ({ email, password }: SignInFormValues) => {
      try {
        setIsSigningIn(true)
        setActionError(null)

        const userNeedsPasswordChange =
          await checkUserNeedsPasswordChange(email)

        if (userNeedsPasswordChange) {
          await sendForgotPasswordEmail(email)
          return
        }

        const result = await handleSignIn(
          email,
          password,
          captchaInfo as CaptchaInfo
        )

        if (result === "not confirmed") {
          sendConfirmationEmail()
          return
        }

        const walletConnected =
          result.user.get("custom:walletConnected") === "1"
        const accelbyteMigrated =
          result.user.get("custom:accelbyteMigrated") === "1"
        const hasAccelbytUserId = !!result.user.get("custom:accelbyteUserID")

        if (walletConnected && !accelbyteMigrated && hasAccelbytUserId) {
          await sendMigratedUserRequest({
            accessToken: result.session.accessToken.jwtToken,
            idToken: result.session.idToken.jwtToken,
          })
          return
        }

        try {
          const userProfile = await getUserProfile({
            accessToken: result.session.accessToken.jwtToken,
          })

          const params = new URLSearchParams()
          params.set("walletType", userProfile.walletType.toString())

          await handleRedirection({ params })
        } catch (error: any) {
          handleRedirection()
        }

        return
      } catch (error: any) {
        console.error(error)
        tries.current += 1
        setCaptchaInfo(null)
        setActionError(
          error.message || "Sign in failed, please try again later"
        )
      } finally {
        setIsSigningIn(false)
      }
    }
  )

  const handleEmailSentReset = () => {
    setEmailSent(false)
  }

  const handleSignInAgain = () => {
    handleSignOut()
    logout()
    setIsConnected(false)
    setIsSigningIn(false)
    reset()
  }

  useEffect(() => {
    if (user && session && !actionError) {
      if (
        user.get("custom:walletConnected") === "1" &&
        !isConnected &&
        !isSigningIn
      ) {
        setIsConnected(true)
      }
      if (user.get("custom:walletConnected") !== "1") {
        handleRedirection()
      }
    }
  }, [user, actionError])

  useEffect(() => {
    const origin = searchParams.get("origin")
    const serviceurl = searchParams.get("serviceurl")

    if (origin) {
      localStorage.setItem("origin", origin)
    }
    if (serviceurl) {
      localStorage.setItem("serviceurl", serviceurl)
    }
  }, [])

  useEffect(() => {
    if (
      touchedFields &&
      Object.keys(touchedFields).length === 0 &&
      isValid &&
      password.length > 0
    ) {
      handleSignInForm()
    }
  }, [touchedFields, password, isValid])

  return {
    selectors: {
      errors,
      rememberMe,
      emailSent,
      actionError,
      isSigningIn,
      isConnected,
      isValid: captchaInfo !== null && isValid,
      tries: tries.current,
    },
    actions: {
      register,
      handleSignInForm,
      handleEmailSentReset,
      handleSignInAgain,
      setCaptchaInfo,
    },
  }
}
